// Imports => React
import React, { useMemo } from 'react';
import { inject } from 'mobx-react';
import { observer } from 'mobx-react-lite';
import clsx from 'clsx';

// Imports => Constants
import { VISUALS } from '@constants';

// Imports => Atoms
import { AcContainer, AcRow, AcColumn } from '@atoms/ac-grid';
import AcRichContent from '@atoms/ac-rich-content/ac-rich-content.web';

const _CLASSES = {
	MAIN: 'ac-location-required-modal',
	CONTENT: 'ac-location-required-modal__content',
};

const AcNotConnectedModal = ({ store, callback }) => {
	const getContentClassNames = useMemo(() => {
		return clsx([_CLASSES.CONTENT]);
	}, []);

	const getStyleClassNames = useMemo(() => {
		return clsx([_CLASSES.MAIN]);
	}, []);

	return (
		<div className={getStyleClassNames}>
			<div className={getContentClassNames}>
				<AcContainer fluid className={'h-text--align-center'}>
					<AcRow>
						<AcColumn>
							<img src={VISUALS.U1F623} alt={'no connection'} />
						</AcColumn>
					</AcRow>

					<AcRow className={'h-margin-top-25'}>
						<AcColumn>
							<AcRichContent
								content={
									'<p class="h-text-body-large">Ohjee, de internetverbinding lijkt offline te zijn!</p>'
								}
							/>
						</AcColumn>
					</AcRow>
				</AcContainer>
			</div>
		</div>
	);
};

export default inject('store')(observer(AcNotConnectedModal));
