// Imports => React
import React, { useMemo } from 'react';
import { inject } from 'mobx-react';
import { observer } from 'mobx-react-lite';
import clsx from 'clsx';

// Imports => Constants
import { THEMES, TYPES, VARIANTS } from '@constants';

// Imports => Atoms
import { AcContainer, AcRow, AcColumn } from '@atoms/ac-grid';
import AcRichContent from '@atoms/ac-rich-content/ac-rich-content.web';
import AcButton from '@atoms/ac-button/ac-button.web';

const _CLASSES = {
	MAIN: 'ac-leave-conversation-modal',
	CONTENT: 'ac-leave-conversation-modal__content',
};

const AcLeaveConversationModal = ({ store, callback, cancel }) => {
	const handleCallback = () => {
		if (callback) callback();
	};

	const handleCancel = event => {
		if (cancel) cancel();
	};

	const getSubmitButtonOptions = useMemo(() => {
		return {
			theme: THEMES.ALPHA,
			type: TYPES.BUTTON,
			className: 'h-margin-bottom-0',
			block: true,
			callback: handleCallback,
		};
	}, []);

	const getCancelButtonOptions = useMemo(() => {
		return {
			theme: THEMES.OMEGA,
			variant: VARIANTS.OUTLINE,
			type: TYPES.BUTTON,
			className: 'h-margin-bottom-0',
			block: true,
			callback: handleCancel,
		};
	}, []);

	const getContentClassNames = useMemo(() => {
		return clsx([_CLASSES.CONTENT]);
	}, []);

	const getStyleClassNames = useMemo(() => {
		return clsx([_CLASSES.MAIN]);
	}, []);

	return (
		<div className={getStyleClassNames}>
			<div className={getContentClassNames}>
				<AcContainer fluid className={'h-text--align-center'}>
					<AcRow>
						<AcColumn>
							<AcRichContent
								content={
									'<p class="h-text-body-large">Je ingevulde antwoorden gaan hierbij verloren.</p>'
								}
							/>
						</AcColumn>
					</AcRow>

					<AcRow className={'h-margin-top-25'}>
						<AcColumn>
							<AcButton {...getSubmitButtonOptions}>
								<span>Ja, verlaten</span>
							</AcButton>
						</AcColumn>
					</AcRow>

					<AcRow className={'h-margin-top-15'}>
						<AcColumn>
							<AcButton {...getCancelButtonOptions}>
								<span>Annuleren</span>
							</AcButton>
						</AcColumn>
					</AcRow>
				</AcContainer>
			</div>
		</div>
	);
};

export default inject('store')(observer(AcLeaveConversationModal));
