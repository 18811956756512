import React, { useEffect, useMemo, memo } from 'react';
// import videojs from 'video.js';
import videojs from 'video.js/dist/alt/video.core.novtt.js';
import clsx from 'clsx';

// Imports => Constants
import config from '@config';

// Imports => Constants
import { ICONS, SIZES } from '@constants';

// Imports => Atoms
import AcBadge from '@atoms/ac-badge/ac-badge.web';
import AcIcon from '@atoms/ac-icon/ac-icon.web';

const _CLASSES = {
	MAIN: 'ac-gif',
	WRP: 'ac-gif-wrp',
	BADGE: 'ac-gif-wrp--badge',
	MASKED: 'ac-gif--masked',
	MASK: 'ac-gif__mask',
	OMEGA: 'ac-gif--omega',
};

const defaultSource = {
	mp4: null,
	webm: null,
};

const AcGif = ({
	source = defaultSource,
	masked = false,
	icon = false,
	theme = null,
	badge = null,
	badgeSize = SIZES.LARGE,
}) => {
	const playVideo = () => {
		const $video = document.querySelector('.gif-video');

		const options = {
			preload: 'auto',
			playsinline: true,
			autoplay: true,
			muted: true,
			loop: true,
			controls: false,
			sources: [
				{
					src: `${config.appUrl}${source.mp4}`,
					type: 'video/mp4',
				},
			],
		};

		if ($video) videojs($video, options);
	};

	useEffect(() => {
		if (source && source.mp4) playVideo();
	}, []);

	const getMaskClassNames = useMemo(() => {
		return clsx(_CLASSES.MASK);
	}, [masked]);

	const getMainClassNames = useMemo(() => {
		return clsx(
			_CLASSES.MAIN,
			masked && _CLASSES.MASKED,
			theme && _CLASSES[theme.toUpperCase()]
		);
	}, [masked, theme]);

	const getWrpClassNames = useMemo(() => {
		return clsx(_CLASSES.WRP, badge && _CLASSES.BADGE);
	}, [badge]);

	return (
		<div className={getWrpClassNames}>
			<div className={getMainClassNames}>
				<video className={'gif-video'}></video>
			</div>

			{masked && (
				<div className={getMaskClassNames}>
					{icon && <AcIcon icon={ICONS.CHECK_ALL} />}
				</div>
			)}

			{badge && <AcBadge value={badge} size={badgeSize} />}
		</div>
	);
};

export default memo(AcGif);
