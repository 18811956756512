import React, { useMemo, memo } from 'react';
import clsx from 'clsx';

// Imports => Atoms
import AcCard from '@atoms/ac-card/ac-card.web';

const _CLASSES = {
	MAIN: 'ac-answer-card',
	SELECTED: 'ac-answer-card--selected',
	HORIZONTAL: 'ac-answer-card--horizontal',
};

const AcAnswerCard = ({ horizontal, selected, children, callback }) => {
	const getMainClassNames = useMemo(() => {
		return clsx(
			_CLASSES.MAIN,
			selected && _CLASSES.SELECTED,
			horizontal && _CLASSES.HORIZONTAL
		);
	}, [selected, horizontal]);

	return (
		<AcCard className={getMainClassNames} callback={callback}>
			{children}
		</AcCard>
	);
};

export default memo(AcAnswerCard);
