import React, { useCallback, useMemo, memo } from 'react';
import { withRouter } from 'react-router-dom';
import clsx from 'clsx';

// Imports => Constants
import { DEFAULT_ROUTE } from '@constants';

// Imports => Components
import AcNavigation from '@components/ac-navigation/ac-navigation.web';

// Imports => Atoms
import AcLogo from '@atoms/ac-logo/ac-logo.web';
import AcBackButton from '@atoms/ac-back-button/ac-back-button.web';

const _CLASSES = {
	MAIN: 'ac-header',
	WHITE: 'ac-header--white',
	ALPHA: 'ac-header--alpha',
	OMEGA: 'ac-header--omega',
};

const AcHeader = ({
	children,
	history,
	withLogo = false,
	withNavigation = false,
	withBackButton = false,
	backButtonTheme = null,
	backButtonCallback = null,
	theme,
}) => {
	const backToHome = useCallback(() => {
		const { push } = history;
		if (push) push(DEFAULT_ROUTE.path);
	}, []);

	const getMainClassNames = useMemo(() => {
		return clsx(_CLASSES.MAIN, theme && _CLASSES[theme.toUpperCase()]);
	}, [theme]);

	const renderLogo = useMemo(() => {
		return <AcLogo callback={backToHome} />;
	}, [withLogo]);

	const renderBackButton = useMemo(() => {
		if (backButtonCallback) {
			return (
				<AcBackButton theme={backButtonTheme} callback={backButtonCallback} />
			);
		}
		return <AcBackButton theme={backButtonTheme} />;
	}, [withBackButton, backButtonTheme, backButtonCallback]);

	const renderNavigation = useMemo(() => {
		return <AcNavigation />;
	}, [withNavigation]);

	return (
		<header className={getMainClassNames} id={'ac-header'}>
			{withLogo && renderLogo}
			{withBackButton && renderBackButton}
			{children}
			{withNavigation && renderNavigation}
		</header>
	);
};

export default withRouter(memo(AcHeader));
