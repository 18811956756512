import React, { useMemo, memo } from 'react';
import clsx from 'clsx';

// Imports => Utilities
import { AcSupportsWEBP } from '@utils';

const _CLASSES = {
	WRP: 'ac-pearl-wrp',
	READY: 'ac-pearl-wrp--ready',
	PEARL: {
		MAIN: 'ac-pearl',
		VISUAL: 'ac-pearl__visual',
	},
};

const AcPearls = ({ ready = false }) => {
	const getVisualClassNames = useMemo(() => {
		return clsx(_CLASSES.PEARL.VISUAL);
	}, []);

	const getPearlClassNames = useMemo(() => {
		return clsx(_CLASSES.PEARL.MAIN);
	}, []);

	const getMainClassNames = useMemo(() => {
		return clsx(_CLASSES.WRP, ready && _CLASSES.READY);
	}, [ready]);

	const renderPearl = useMemo(() => {
		const source = AcSupportsWEBP() ? 'parel.webp' : 'parel.png';
		const img = require(`@assets/images/${source}`);

		return (
			<img src={img} className={getVisualClassNames} alt={'pearl visual'} />
		);
	}, []);

	return (
		<div className={getMainClassNames}>
			<div className={getPearlClassNames}>{renderPearl}</div>
			<div className={getPearlClassNames}>{renderPearl}</div>
			<div className={getPearlClassNames}>{renderPearl}</div>
			<div className={getPearlClassNames}>{renderPearl}</div>
			<div className={getPearlClassNames}>{renderPearl}</div>
			<div className={getPearlClassNames}>{renderPearl}</div>
			<div className={getPearlClassNames}>{renderPearl}</div>
		</div>
	);
};

export default memo(AcPearls);
