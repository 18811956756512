import React, { useEffect, useState, useMemo, memo } from 'react';
import clsx from 'clsx';

const _CLASSES = {
	MAIN: 'ac-conversation-progress',
	BAR: 'ac-conversation-progress__bar',
	TRACKER: 'ac-conversation-progress__tracker',
};

const AcConversationProgress = ({ value = 0, max = 10 }) => {
	const [position, setPosition] = useState(0);

	const getTrackerClassNames = useMemo(() => {
		return clsx(_CLASSES.TRACKER);
	}, []);

	const getBarClassNames = useMemo(() => {
		return clsx(_CLASSES.BAR);
	}, []);

	const getMainClassNames = useMemo(() => {
		return clsx(_CLASSES.MAIN);
	}, []);

	const calculateProgress = useMemo(() => {
		let proc = (value / max) * 100;
		proc = proc > 100 ? 100 : proc < 0 ? 0 : proc;

		return proc;
	}, [value, max]);

	const getTrackerPosition = useMemo(() => {
		return { width: `${position}%` };
	}, [position]);

	const init = () => {
		setPosition(calculateProgress);
	};

	useEffect(() => {
		init();
	}, [value, max]);

	return (
		<div className={getMainClassNames}>
			<div className={getBarClassNames}>
				<div className={getTrackerClassNames} style={getTrackerPosition} />
			</div>
		</div>
	);
};

export default memo(AcConversationProgress);
