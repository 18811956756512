import React, { useEffect, useState, useMemo, memo } from 'react';
import { withRouter } from 'react-router-dom';
import CountUp from 'react-countup';
import Bounce from 'react-reveal/Bounce';
import { Fade } from 'react-reveal';
import clsx from 'clsx';

// Imports => Constants
import { ROUTES } from '@constants';

// Imports => Utilities
import { AcSupportsWEBP } from '@utils';

const _CLASSES = {
	MAIN: 'ac-bitterballs',
	HIGHSCORE: 'ac-bitterballs--highscore',
	VALUE: 'ac-bitterballs__value',
	LABEL: 'ac-bitterballs__label',
	BAR: 'ac-bitterballs__bar',
	TRACKER: 'ac-bitterballs__tracker',
	P_VALUE: 'ac-bitterballs__pvalue',
	VISUAL: {
		MAIN: 'ac-bitterballs__visual',
		WRP: 'ac-bitterballs__visual-wrp',
	},
};

const AcBitterballs = ({
	history,
	ready = false,
	value = 0,
	max = 0,
	delay = 1500,
	progress,
}) => {
	const [highscore, setHighScore] = useState(false);

	useEffect(() => {
		const $tracker = document.querySelector('.ac-bitterballs__tracker');

		if ($tracker) {
			if (value > max) {
				$tracker.addEventListener('transitionend', showTheHighScore, true);
			}
		}
	}, []);

	const showTheHighScore = () => {
		const $tracker = document.querySelector('.ac-bitterballs__tracker');

		if ($tracker) {
			setHighScore(true);
			$tracker.removeEventListener('transitionend', showTheHighScore, true);
		}
	};

	const handleClick = () => {
		const { push } = history;
		if (push) push(ROUTES.SCORE_FAQ.path);
	};

	const getVisualClassNames = useMemo(() => {
		return clsx(_CLASSES.VISUAL.MAIN);
	}, []);

	const getVisualWrpClassNames = useMemo(() => {
		return clsx(_CLASSES.VISUAL.WRP);
	}, []);

	const getProgressValueClassNames = useMemo(() => {
		return clsx(_CLASSES.P_VALUE);
	}, []);

	const getProgressTrackerClassNames = useMemo(() => {
		return clsx(_CLASSES.TRACKER);
	}, []);

	const getProgressBarClassNames = useMemo(() => {
		return clsx(_CLASSES.BAR);
	}, []);

	const getLabelClassNames = useMemo(() => {
		return clsx(_CLASSES.LABEL);
	}, []);

	const getValueClassNames = useMemo(() => {
		return clsx(_CLASSES.VALUE);
	}, []);

	const getMainClassNames = useMemo(() => {
		return clsx(_CLASSES.MAIN, highscore && _CLASSES.HIGHSCORE);
	}, [highscore]);

	const getValue = useMemo(() => {
		return `+${value}`;
	}, [value]);

	const getLabel = useMemo(() => {
		return `bitterbal${value > 1 || value === 0 ? 'len' : ''}`;
	}, [value]);

	const getTrackerPosition = useMemo(() => {
		let proc = (value / max) * 100;
		proc = proc > 100 ? 100 : proc < 0 ? 0 : proc;

		if (!ready) proc = 0;

		return { transform: `scaleX(${proc / 100})` };
	}, [ready, value, max]);

	const getCountUpOptions = useMemo(() => {
		if (!ready) {
			return {
				end: 0,
				duration: 0,
				delay: 0,
				preserveValue: true,
				easingFn: (t, b, c, d) => {
					return c * (t /= d) * t * t + b;
				},
			};
		}

		return {
			end: value,
			duration: 1.5,
			delay: 0,
			preserveValue: true,
			easingFn: (t, b, c, d) => {
				return c * (t /= d) * t * t + b;
			},
		};
	}, [value, ready]);

	const renderVisual = useMemo(() => {
		const source = AcSupportsWEBP()
			? 'bitterbal-vlag-korter@3x.webp'
			: 'bitterbal-vlag-korter@3x.png';
		const img = require(`@assets/images/${source}`);

		return (
			<img
				src={img}
				className={getVisualClassNames}
				alt={'bitterball with flag'}
			/>
		);
	}, []);

	return (
		<div className={getMainClassNames} onClick={handleClick}>
			<div className={getLabelClassNames}>
				<Bounce duration={500} delay={delay} when={ready}>
					<div className={getVisualWrpClassNames}>{renderVisual}</div>
				</Bounce>
				{!progress && (
					<Fade
						left
						distance={'30px'}
						duration={500}
						delay={delay}
						when={ready}
					>
						<span className={getValueClassNames}>{getValue}</span>
						{getLabel}
					</Fade>
				)}
				{progress && (
					<>
						<div className={getProgressBarClassNames}>
							<div
								className={getProgressTrackerClassNames}
								style={getTrackerPosition}
							/>
						</div>
						<div className={getProgressValueClassNames}>
							<span>
								<CountUp {...getCountUpOptions} />
							</span>
							/{max}
						</div>
					</>
				)}
			</div>
		</div>
	);
};

export default withRouter(memo(AcBitterballs));
