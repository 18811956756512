import React, { useEffect, useState, useMemo, memo } from 'react';
import { Fade } from 'react-reveal';

// Imports => Constants
import { KEYS, THEMES, TYPES, VARIANTS } from '@constants';

// Imports => Utilities
import { AcUUID, AcIsEmptyString, AcIsSet, AcIsNull } from '@utils';

// Imports => Atoms
import { AcContainer, AcRow, AcColumn } from '@atoms/ac-grid';
import AcBottomDrawer from '@atoms/ac-bottom-drawer/ac-bottom-drawer.web';
import AcHeading from '@atoms/ac-heading/ac-heading.web';
import AcRichContent from '@atoms/ac-rich-content/ac-rich-content.web';
import AcTextInput from '@atoms/ac-text-input/ac-text-input.web';
import AcButton from '@atoms/ac-button/ac-button.web';

let delay = null;

const AcQuestionExtraQuestion = ({
  id,
  title,
  question,
  value = '',
  callback,
  skip,
}) => {
  const [extraQuestion, setExtraQuestion] = useState(value);
  const [errors, setErrors] = useState({
    [KEYS.EXTRA_QUESTION]: value ? null : undefined,
  });
  const [drawerVisible, setDrawerVisible] = useState(false);

  const input_id = AcUUID();
  const button_id = AcUUID();

  const hasErrors = useMemo(() => {
    const result = !!errors[KEYS.EXTRA_QUESTION];
    return result;
  }, [extraQuestion, errors]);

  const showDrawer = () => {
    const $input = document.getElementById(input_id);

    if ($input && $input === document.activeElement) return;

    if (AcIsSet(extraQuestion) && !errors[KEYS.EXTRA_QUESTION]) {
      setDrawerVisible(true);
    }
  };

  const hideDrawer = () => {
    setDrawerVisible(false);
  };

  useEffect(() => {
    if (delay) clearTimeout(delay);
    delay = setTimeout(() => {
      if (extraQuestion) showDrawer();
      else hideDrawer();
    }, 600);
  }, [extraQuestion]);

  const handleInputValidation = (name, value, type) => {
    let result = errors;

    // switch (name) {
    // 	case KEYS.EXTRA_QUESTION:
    // 		if (AcIsSet(value) && !AcIsEmptyString(value) && !value) {
    // 			result[name] = 'Dit is geen geldig e-mailadres';
    // 		} else {
    // 			result[name] = null;
    // 		}
    // 		break;
    // 	default:
    // }

    setErrors(result);

    return result[name];
  };

  const handleFormSubmit = event => {
    if (event && event.preventDefault) event.preventDefault();
    if (AcIsSet(extraQuestion) && !AcIsEmptyString(extraQuestion)) {
      if (callback) callback(id, extraQuestion);
    }
  };

  const handleInputChange = (event, name, value, type) => {
    if (event && event.persist) event.persist();

    switch (name) {
      case KEYS.EXTRA_QUESTION:
        setExtraQuestion(value);
        break;
      default:
    }
  };

  const getExtraQuestionInputOptions = useMemo(() => {
    return {
      label: 'Antwoord',
      type: TYPES.TEXTAREA,
      name: KEYS.EXTRA_QUESTION,
      value: extraQuestion,
      theme: THEMES.WHITE,
      height: 'unset',
      rows: '4',
      validation: handleInputValidation,
      callback: handleInputChange,
    };
  }, [extraQuestion, hideDrawer, showDrawer]);

  const getNoExtraQuestionButtonOptions = useMemo(() => {
    return {
      type: TYPES.BUTTON,
      theme: THEMES.WHITE,
      variant: VARIANTS.OUTLINE,
      callback: () => callback && callback(id, ''),
      className: 'h-margin-top-30',
    };
  }, []);

  const getSubmitButtonOptions = useMemo(() => {
    return {
      id: button_id,
      type: TYPES.BUTTON,
      theme: THEMES.MINTY,
      disabled: hasErrors,
      title: 'Bevestig',
      callback: handleFormSubmit,
    };
  }, [extraQuestion, errors, hasErrors]);

  return (
    <>
      <AcContainer
        className={'h-text--align-center h-margin-top-0 h-padding-top-60'}
      >
        {title && (
          <AcRow>
            <AcColumn>
              <AcHeading rank={3} theme={THEMES.WHITE}>
                {title}
              </AcHeading>
            </AcColumn>
          </AcRow>
        )}

        {question && (
          <AcRow>
            <AcColumn>
              <AcRichContent
                content={`<p class="h-text-body-large">${question}</p>`}
                className={'h-text--color-white'}
              />
            </AcColumn>
          </AcRow>
        )}

        <AcRow>
          <AcColumn>
            <form onSubmit={handleFormSubmit}>
              <AcTextInput {...getExtraQuestionInputOptions} />
            </form>
          </AcColumn>
        </AcRow>

        <AcRow>
          <AcColumn>
            <Fade
              bottom
              distance={'20px'}
              duration={200}
              when={hasErrors || !extraQuestion}
              delay={300}
            >
              <AcButton {...getNoExtraQuestionButtonOptions}>
                <span>Geen antwoord</span>
              </AcButton>
            </Fade>
          </AcColumn>
        </AcRow>

        <div style={{ height: '10rem' }} />
      </AcContainer>

      <AcBottomDrawer visible={drawerVisible} theme={THEMES.OMEGA}>
        <AcContainer fluid className={'h-text--align-center'}>
          <AcRow>
            <AcColumn>
              <AcButton {...getSubmitButtonOptions}>
                <span>Bevestig</span>
              </AcButton>
            </AcColumn>
          </AcRow>
        </AcContainer>
      </AcBottomDrawer>
    </>
  );
};

export default memo(AcQuestionExtraQuestion);
