import React, { useEffect, useState, useMemo, memo } from 'react';
import { Fade } from 'react-reveal';

// Imports => Constants
import { KEYS, THEMES, TYPES, VARIANTS } from '@constants';

// Imports => Utilities
import { AcUUID, AcIsPostalCode, AcIsEmptyString, AcIsSet, AcIsNull } from '@utils';

// Imports => Atoms
import { AcContainer, AcRow, AcColumn } from '@atoms/ac-grid';
import AcBottomDrawer from '@atoms/ac-bottom-drawer/ac-bottom-drawer.web';
import AcHeading from '@atoms/ac-heading/ac-heading.web';
import AcRichContent from '@atoms/ac-rich-content/ac-rich-content.web';
import AcTextInput from '@atoms/ac-text-input/ac-text-input.web';
import AcButton from '@atoms/ac-button/ac-button.web';

let delay = null;

const AcQuestionPostalcode = ({
	id,
	title,
	question,
	value = '',
	callback,
	skip,
}) => {
	const [postalcode, setPostalcode] = useState(value);
	const [errors, setErrors] = useState({
		[KEYS.POSTALCODE]: value ? null : undefined,
	});
	const [drawerVisible, setDrawerVisible] = useState(false);

	const input_id = AcUUID();
	const button_id = AcUUID();

	const hasErrors = useMemo(() => {
		const result = !AcIsNull(errors[KEYS.POSTALCODE]);
		return result;
	}, [postalcode, errors]);

	const showDrawer = () => {
		const $input = document.getElementById(input_id);

		if ($input && $input === document.activeElement) return;

		if (AcIsSet(postalcode) && AcIsNull(errors[KEYS.POSTALCODE])) {
			setDrawerVisible(true);
		}
	};

	const hideDrawer = () => {
		setDrawerVisible(false);
	};

	useEffect(() => {
		if (delay) clearTimeout(delay);
		delay = setTimeout(() => {
			if (postalcode) showDrawer();
			else hideDrawer();
		}, 600);
	}, [postalcode]);

	const handleFormSubmit = event => {
		if (event && event.preventDefault) event.preventDefault();
		if (AcIsSet(postalcode) && !AcIsEmptyString(postalcode) && AcIsPostalCode(postalcode)) {
			if (callback) callback(id, postalcode);
		}
	};

	const handleInputValidation = (name, value, type) => {
		let result = errors;

		switch (name) {
			case KEYS.POSTALCODE:
				if (AcIsSet(value) && !AcIsEmptyString(value) && !AcIsPostalCode(value)) {
					result[name] = 'Dit is geen geldige postcode';
				} else {
					result[name] = null;
				}
				break;
			default:
		}

		setErrors(result);

		return result[name];
	};

	const handleInputChange = (event, name, value, type) => {
		if (event && event.persist) event.persist();

		switch (name) {
			case KEYS.POSTALCODE:
				setPostalcode(value);
				break;
			default:
		}
	};

	const getPostalcodeInputOptions = useMemo(() => {
		return {
			label: 'Postcode 1234 AB',
			type: TYPES.TEXT,
			name: KEYS.POSTALCODE,
			value: postalcode,
			theme: THEMES.WHITE,
			required: true,
			validation: handleInputValidation,
			autoComplete: 'new-password',
			callback: handleInputChange,
			onFocus: hideDrawer,
			onBlur: showDrawer,
		};
	}, [postalcode, hideDrawer, showDrawer]);

	const getNoPostalcodeButtonOptions = useMemo(() => {
		return {
			type: TYPES.BUTTON,
			theme: THEMES.WHITE,
			variant: VARIANTS.OUTLINE,
			callback: skip,
			className: 'h-margin-top-30',
		};
	}, []);

	const getSubmitButtonOptions = useMemo(() => {
		return {
			id: button_id,
			type: TYPES.BUTTON,
			theme: THEMES.MINTY,
			disabled: hasErrors,
			title: 'Bevestig',
			callback: handleFormSubmit,
		};
	}, [postalcode, errors, hasErrors]);

	return (
		<>
			<AcContainer
				className={'h-text--align-center h-margin-top-0 h-padding-top-60'}
			>
				{title && (
					<AcRow>
						<AcColumn>
							<AcHeading rank={3} theme={THEMES.WHITE}>
								{title}
							</AcHeading>
						</AcColumn>
					</AcRow>
				)}

				{question && (
					<AcRow>
						<AcColumn>
							<AcRichContent
								content={`<p class="h-text-body-large">${question}</p>`}
								className={'h-text--color-white'}
							/>
						</AcColumn>
					</AcRow>
				)}

				<AcRow>
					<AcColumn>
						<form onSubmit={handleFormSubmit}>
							<AcTextInput {...getPostalcodeInputOptions} />
						</form>
					</AcColumn>
				</AcRow>

				<AcRow>
					<AcColumn>
						<Fade
							bottom
							distance={'20px'}
							duration={200}
							when={hasErrors || AcIsEmptyString(postalcode)}
						>
							<AcButton {...getNoPostalcodeButtonOptions}>
								<span>Geen postcode</span>
							</AcButton>
						</Fade>
					</AcColumn>
				</AcRow>

				<div style={{ height: '10rem' }} />
			</AcContainer>

			<AcBottomDrawer visible={drawerVisible} theme={THEMES.OMEGA}>
				<AcContainer fluid className={'h-text--align-center'}>
					<AcRow>
						<AcColumn>
							<AcButton {...getSubmitButtonOptions}>
								<span>Bevestig</span>
							</AcButton>
						</AcColumn>
					</AcRow>
				</AcContainer>
			</AcBottomDrawer>
		</>
	);
};

export default memo(AcQuestionPostalcode);
