// Imports => React
import React, { useMemo, memo } from 'react';
import clsx from 'clsx';

import QR from '@assets/images/conversation-qr.png';

const _CLASSES = {
  MAIN: 'ac-conversation-qr',
};
// Component
const AcConversationQR = ({ icon }) => {
  const getStyleClassNames = useMemo(() => {
    return clsx(_CLASSES.MAIN);
  }, [icon]);

  return (
    <div
      className={getStyleClassNames}
      style={{
      }}
    >
      <img src={QR} alt="VVD Stemmen QR code" />
    </div>
  );
};

export default memo(AcConversationQR);
