// Imports => React
import React, { useMemo, memo } from 'react';
import clsx from 'clsx';

// Imports => Constants
import { DATETIME_FORMATS, ICONS, ROLES, THEMES } from '@constants';

// Imports => Utilities
import { AcFormatDate } from '@utils';

// Imports => Atoms
import AcCard from '@atoms/ac-card/ac-card.web';
import AcIcon from '@atoms/ac-icon/ac-icon.web';
import AcHeading from '@atoms/ac-heading/ac-heading.web';
import AcRipple from '@atoms/ac-ripple/ac-ripple.web';

import Door from '@assets/images/door.svg'
import Gps from '@assets/images/gps.svg'

const _CLASSES = {
	MAIN: 'ac-campaign-card',
	PAST: 'ac-campaign-card--past',
	MARKER: 'ac-campaign-card__marker',
	DETAILS: 'ac-campaign-card__details',
	TITLE: 'ac-campaign-card__title',
	SUBTITLE: 'ac-campaign-card__subtitle',
	DESCRIPTION: 'ac-campaign-card__description',
	SUBSCRIBED: 'ac-campaign-card__subscribed',
};

const AcCampaignCard = ({
	campaign,
	datetime,
	id,
	introduction,
	municipality,
	name,
	role = false,
	participants,
	started = false,
	subscribed = true,
	past = false,
	type,
	callback = () => { },
}) => {
	const handleCallback = () => {
		if (callback) callback();
	};

	const getSubscribedClassNames = useMemo(() => {
		return clsx(_CLASSES.SUBSCRIBED);
	}, []);

	const getDescriptionClassNames = useMemo(() => {
		return clsx(_CLASSES.DESCRIPTION);
	}, []);

	const getSubtitleClassNames = useMemo(() => {
		return clsx(_CLASSES.SUBTITLE);
	}, []);

	const getTitleClassNames = useMemo(() => {
		return clsx(_CLASSES.TITLE);
	}, []);

	const getDetailsClassNames = useMemo(() => {
		return clsx(_CLASSES.DETAILS);
	}, []);

	// const getMarkerClassNames = useMemo(() => {
	// 	return clsx(_CLASSES.MARKER);
	// }, []);

	const getMainClassNames = useMemo(() => {
		return clsx(_CLASSES.MAIN, past && _CLASSES.PAST);
	}, [past]);

	const renderMunicipality = useMemo(() => {
		if (!role) return null;
		if (!municipality) return null;
		if (role !== ROLES.CAMPAIGN_MANAGER) return null;

		return <div className={getDescriptionClassNames}>{municipality}</div>;
	}, [municipality, role]);

	const renderDate = useMemo(() => {
		if (!datetime) return null;

		let content = AcFormatDate(
			datetime,
			DATETIME_FORMATS.RAW_DATETIME_WITH_YEAR,
			DATETIME_FORMATS.DATETIME_WITH_YEAR
		);

		content = `${content} uur`;

		return <div className={getSubtitleClassNames}>{content}</div>;
	}, [datetime]);

	return (
		<AcCard className={getMainClassNames} callback={handleCallback}>
			<AcRipple theme={THEMES.LIGHT} size={'small'} simple />
			{type === 'door-to-door' ? <Door className="ac-svg-icon" /> : <Gps className="ac-svg-icon" />}
			<div className={getDetailsClassNames}>
				<AcHeading rank={5} className={getTitleClassNames}>
					{name}
				</AcHeading>
				{renderMunicipality}
				{renderDate}
			</div>
			{subscribed && (
				<AcIcon icon={ICONS.CHECK} className={getSubscribedClassNames} />
			)}
		</AcCard>
	);
};

export default memo(AcCampaignCard);
