import React, { useEffect, useMemo, memo } from 'react';
import clsx from 'clsx';

// Imports => Utilities
import { AcConfettiInstance } from '@utils';

const _CLASSES = {
	MAIN: 'ac-confetti',
};

let timer;
let instance;

const AcConfetti = ({ delay = 0, duration = 10000 }) => {
	useEffect(() => {
		if (timer) clearTimeout(timer);
		timer = setTimeout(() => {
			instance = AcConfettiInstance(destroy);
		}, delay);
	}, []);

	const destroy = () => {
		if (!duration) return;
		if (timer) clearTimeout(timer);
		timer = setTimeout(() => {
			if (instance) instance.destroy();
		}, duration);
	};

	const getMainClassNames = useMemo(() => {
		return clsx(_CLASSES.MAIN);
	}, []);

	return <canvas className={getMainClassNames} id={getMainClassNames} />;
};

export default memo(AcConfetti);
