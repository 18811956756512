import React, { useEffect, useState, useMemo, memo } from 'react';
import { Fade } from 'react-reveal';

// Imports => Constants
import { KEYS, THEMES, TYPES, VARIANTS } from '@constants';

// Imports => Utilities
import { AcUUID, AcIsEmptyString, AcIsSet, AcIsNull } from '@utils';

// Imports => Atoms
import { AcContainer, AcRow, AcColumn } from '@atoms/ac-grid';
import AcBottomDrawer from '@atoms/ac-bottom-drawer/ac-bottom-drawer.web';
import AcHeading from '@atoms/ac-heading/ac-heading.web';
import AcRichContent from '@atoms/ac-rich-content/ac-rich-content.web';
import AcTextInput from '@atoms/ac-text-input/ac-text-input.web';
import AcButton from '@atoms/ac-button/ac-button.web';

let delay = null;

const AcQuestionOpen = ({
	id,
	title,
	question,
	value = '',
	callback,
	skip,
}) => {
	const [openAnswer, setOpenAnswer] = useState(value);
	const [errors, setErrors] = useState({
		[KEYS.OPEN]: value ? null : undefined,
	});

	const [drawerVisible, setDrawerVisible] = useState(false);

	const input_id = AcUUID();
	const button_id = AcUUID();

	const hasErrors = useMemo(() => {
		const result = !AcIsNull(errors[KEYS.OPEN]);
		return result;
	}, [openAnswer, errors]);

	const showDrawer = () => {
		const $input = document.getElementById(input_id);

		if ($input && $input === document.activeElement) return;

		if (AcIsSet(openAnswer) && AcIsNull(errors[KEYS.OPEN])) {
			setDrawerVisible(true);
		}
	};

	const hideDrawer = () => {
		setDrawerVisible(false);
	};

	useEffect(() => {
		if (delay) clearTimeout(delay);
		delay = setTimeout(() => {
			if (openAnswer) showDrawer();
			else hideDrawer();
		}, 600);
	}, [openAnswer]);

	const handleInputValidation = (name, value, type) => {
		let result = errors;

		switch (name) {
			case KEYS.OPEN:
				if (AcIsSet(value) && !AcIsEmptyString(value) && !value) {
					result[name] = 'Dit is geen geldig e-mailadres';
				} else {
					result[name] = null;
				}
				break;
			default:
		}

		setErrors(result);

		return result[name];
	};

	const handleInputChange = (event, name, value, type) => {
		if (event && event.persist) event.persist();

		switch (name) {
			case KEYS.OPEN:
				setOpenAnswer(value);
				break;
			default:
		}
	};

	const handleFormSubmit = async (event) => {
		if (event && event.preventDefault) event.preventDefault();

		if (callback) callback(id, openAnswer && openAnswer)

		setOpenAnswer('')
	};

	console.log('log openAnswer', openAnswer)

	const getOpenAnswerInputOptions = useMemo(() => {
		return {
			label: 'Antwoord',
			type: TYPES.TEXTAREA,
			name: KEYS.OPEN,
			value: openAnswer,
			theme: THEMES.WHITE,
			required: false,
			validation: handleInputValidation,
			autoComplete: 'off',
			callback: handleInputChange,
			// onFocus: hideDrawer,
			// onBlur: showDrawer,
		};
	}, [openAnswer, hideDrawer, showDrawer]);

	const getNoAnswerButtonOptions = useMemo(() => {
		return {
			type: TYPES.BUTTON,
			theme: THEMES.WHITE,
			variant: VARIANTS.OUTLINE,
			callback: handleFormSubmit,
			className: 'h-margin-top-30',
		};
	}, []);

	const getSubmitButtonOptions = useMemo(() => {
		return {
			id: button_id,
			type: TYPES.BUTTON,
			theme: THEMES.MINTY,
			disabled: hasErrors,
			title: 'Bevestig',
			callback: handleFormSubmit,
		};
	}, [openAnswer, errors, hasErrors]);

	console.log('log the value', value)
	return (
		<>
			<AcContainer
				className={'h-text--align-center h-margin-top-0 h-padding-top-60'}
			>
				{title && (
					<AcRow>
						<AcColumn>
							<AcHeading rank={3} theme={THEMES.WHITE}>
								{title}
							</AcHeading>
						</AcColumn>
					</AcRow>
				)}

				{question && (
					<AcRow>
						<AcColumn>
							<AcRichContent
								content={`<p class="h-text-body-large">${question}</p>`}
								className={'h-text--color-white'}
							/>
						</AcColumn>
					</AcRow>
				)}

				<AcRow>
					<AcColumn>
						<form onSubmit={handleFormSubmit}>
							<AcTextInput {...getOpenAnswerInputOptions} />
						</form>
					</AcColumn>
				</AcRow>

				<AcRow>
					<AcColumn>
						<Fade
							bottom
							distance={'20px'}
							duration={200}
							when={hasErrors || AcIsEmptyString(openAnswer)}
						>
							<AcButton {...getNoAnswerButtonOptions}>
								<span>Geen antwoord</span>
							</AcButton>
						</Fade>
					</AcColumn>
				</AcRow>

				<div style={{ height: '10rem' }} />
			</AcContainer>

			<AcBottomDrawer visible={drawerVisible} theme={THEMES.OMEGA}>
				<AcContainer fluid className={'h-text--align-center'}>
					<AcRow>
						<AcColumn>
							<AcButton {...getSubmitButtonOptions}>
								<span>Bevestig</span>
							</AcButton>
						</AcColumn>
					</AcRow>
				</AcContainer>
			</AcBottomDrawer>
		</>
	);
};

export default memo(AcQuestionOpen);
