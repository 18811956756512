import React, { memo, useMemo } from 'react';
import { Fade } from 'react-reveal';

// Imports => Constants
import { THEMES, TYPES, VARIANTS } from '@constants';

// Imports => Atoms
import AcButton from '@atoms/ac-button/ac-button.web';
import { AcColumn, AcContainer, AcRow } from '@atoms/ac-grid';
import AcHeading from '@atoms/ac-heading/ac-heading.web';
import AcRichContent from '@atoms/ac-rich-content/ac-rich-content.web';
import AcConversationQr from '@src/atoms/ac-conversation-qr/ac-conversation-qr.web';

const AcQuestionQR = ({ id, title, question, skip, callback }) => {
  const handleFormSubmit = (event,value) => {
    if (event && event.preventDefault) event.preventDefault();
    if (callback) callback(id, value);
  };

  const getScannedButtonOptions = useMemo(() => {
    return {
      type: TYPES.BUTTON,
      theme: THEMES.WHITE,
      variant: VARIANTS.OUTLINE,
      callback: e => handleFormSubmit(e, 'scanned'),
      className: 'h-margin-top-30',
    };
  }, []);

  const getNotScannedButtonOptions = useMemo(() => {
    return {
      type: TYPES.BUTTON,
      theme: THEMES.WHITE,
      variant: VARIANTS.OUTLINE,
      callback: e => handleFormSubmit(e, 'not_scanned'),
      className: 'h-margin-top-30',
    };
  }, []);

  return (
    <>
      <AcContainer
        className={'h-text--align-center h-margin-top-0 h-padding-top-60'}
      >
        {title && (
          <AcRow>
            <AcColumn>
              <AcHeading rank={3} theme={THEMES.WHITE}>
                {title}
              </AcHeading>
            </AcColumn>
          </AcRow>
        )}

        {question && (
          <AcRow>
            <AcColumn>
              <AcRichContent
                content={`<p class="h-text-body-large">${question}</p>`}
                className={'h-text--color-white'}
              />
            </AcColumn>
          </AcRow>
        )}

        <AcRow>
          <AcColumn>
            <AcConversationQr />
          </AcColumn>
        </AcRow>

        <AcRow>
          <AcColumn>
            <Fade bottom distance={'20px'} duration={200}>
              <AcButton {...getScannedButtonOptions}>
                <span>Gescand</span>
              </AcButton>
            </Fade>
          </AcColumn>
        </AcRow>
        <AcRow>
          <AcColumn>
            <Fade bottom distance={'20px'} duration={200}>
              <AcButton {...getNotScannedButtonOptions}>
                <span>Doorgaan zonder scannen</span>
              </AcButton>
            </Fade>
          </AcColumn>
        </AcRow>

        <div style={{ height: '10rem' }} />
      </AcContainer>
    </>
  );
};

export default memo(AcQuestionQR);
