import React, { useMemo, memo } from 'react';
import RubberBand from 'react-reveal/RubberBand';
import clsx from 'clsx';

// Imports => Constants
import { SIZES } from '@constants';

// Imports => Utilities
import { AcSupportsWEBP } from '@utils';

const _CLASSES = {
	MAIN: 'ac-badge',
	SMALL: 'ac-badge--small',
	LARGE: 'ac-badge--large',
	VALUE: 'ac-badge__value',
};

const AcBadge = ({ value, size = SIZES.SMALL }) => {
	const getValueClassNames = useMemo(() => {
		return clsx(_CLASSES.VALUE);
	}, []);

	const getMainClassNames = useMemo(() => {
		return clsx(_CLASSES.MAIN, size && _CLASSES[size.toUpperCase()]);
	}, [size]);

	const renderBadge = useMemo(() => {
		const source = AcSupportsWEBP() ? `badge.webp` : `badge.png`;
		const img = require(`@assets/images/${source}`);

		return (
			<img src={img} className={'ac-badge__visual'} alt={'badge visual'} />
		);
	}, []);

	return (
		<RubberBand
			duration={800}
			delay={1000}
			appear
			className={getMainClassNames}
		>
			<div className={getMainClassNames}>
				{renderBadge}
				<span className={getValueClassNames}>{value}</span>
			</div>
		</RubberBand>
	);
};

export default memo(AcBadge);
