// Imports => React
import React, { useEffect, useMemo, memo } from 'react';
import clsx from 'clsx';
import { TransitionGroup, CSSTransition } from 'react-transition-group';

// Imports => Atoms
import AcToaster from '@atoms/ac-toaster/ac-toaster.web';

const _CLASSES = {
	MAIN: 'ac-toaster-hoc',
	TRANSITION: 'ac-toaster-transition',
};

const AcToasterHoc = ({ queue, callback }) => {
	useEffect(() => {
		addEvents();

		return () => {
			disableObserver();
		};
	}, []);

	const addEvents = () => {
		document.addEventListener('bd-entered', setObserver, false);
		document.addEventListener('bd-left', disableObserver, false);
		document.addEventListener('bd-visible', setObserver, false);
	};

	const setObserver = event => {
		const $drawer = document.querySelector('.ac-bottom-drawer');
		const $hoc = document.querySelector('.ac-toaster-hoc');

		if (event && event.detail && event.detail.visible) {
			const bottom = $drawer.offsetHeight;
			$hoc.style.bottom = `${bottom + 24}px`;
		} else {
			disableObserver();
		}
	};

	const disableObserver = () => {
		const $hoc = document.querySelector('.ac-toaster-hoc');
		$hoc.style.bottom = '';
	};

	const handleCallback = id => {
		if (callback) callback(id);
	};

	const getStyleClassNames = useMemo(() => {
		return clsx(_CLASSES.MAIN);
	}, []);

	const renderToasts = useMemo(() => {
		if (!queue) return null;

		let len = queue.length;
		let n = 0;
		let result = [];

		for (n; n < len; n++) {
			const toast = queue[n];

			const object = (
				<CSSTransition
					classNames={_CLASSES.TRANSITION}
					timeout={{ enter: 1000, exit: 1000 }}
					key={`ac-toaster-${toast.id}`}
				>
					<AcToaster {...toast} callback={handleCallback} />
				</CSSTransition>
			);

			result.push(object);
		}

		return result;
	}, [queue, callback]);

	return (
		<TransitionGroup className={getStyleClassNames}>
			{renderToasts}
		</TransitionGroup>
	);
};

export default memo(AcToasterHoc);
