// Imports => React
import { useEffect } from 'react';
import { withRouter } from 'react-router-dom';

const AcScrollHOC = ({ history, children }) => {
	useEffect(() => {
		const $scroller = document.getElementById('ac-scroller');
		if ($scroller && $scroller.scrollTo) {
			$scroller.scrollTo(0, 0);
			window.scrollTo(0, 0);
		}
	}, [history.location]);

	return children;
};

export default withRouter(AcScrollHOC);
