// Imports => React
import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

// Import => Utilities
import { AcUUID } from '@utils';

const _CLASSES = {
  MAIN: 'ac-text-input',
  WHITE: 'ac-text-input--white',
  DISABLED: 'ac-text-input--disabled',
  READONLY: 'ac-text-input--readonly',
  ERROR: 'ac-text-input--error',
  SUCCESS: 'ac-text-input--success',
  EMPTY: 'ac-text-input--empty',
  PLACEHOLDER: 'ac-text-input--placeholder',
  LABEL: {
    MAIN: 'ac-text-input__label',
    TITLE: 'ac-text-input__label__title',
    HIDDEN: 'ac-text-input__label--hidden',
  },
  INPUT: {
    MAIN: 'ac-text-input__field',
    PASSWORD: 'ac-text-input__field--password',
    TEXTAREA: 'ac-text-input__field--textarea',
  },
  VALIDATION: {
    ERROR: 'ac-text-input__error',
    SUCCESS: 'ac-text-input__success',
  },
  INSTRUCTIONS: 'ac-text-input__instructions',
  EYE: {
    MAIN: 'ac-text-input__eye',
    OPEN: 'ac-text-input__eye--open',
  },
};

// Controller
class AcTextInputController extends React.Component {
  constructor(props) {
    super(props);

    this.element = React.createRef();

    this.state = {
      hasError: props.error,
      reference: props.id || AcUUID(),
      value: props.value,
      name: props.name,
      tabindex: props.tabindex || 0,
      type: props.type,
      touched: false,
    };

    this.selection = {
      start: false,
      end: false,
    };

    this.setFocus = this.setFocus.bind(this);
  }

  componentDidMount() {
    this.setFocus();
    this.onChange(null, this.state.value, false);

    if (this.element && this.element.current) {
      this.element.current.addEventListener('keyup', this.handleKeyup, false);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    // if (
    //   this.element.current &&
    //   this.element.current.selectionStart &&
    //   this.element.current.selectionEnd
    // ) {
    //   const { selectionStart, selectionEnd } = this.element.current;

    //   const update =
    //     (this.selection.start !== false &&
    //       this.selection.start !== selectionStart) ||
    //     (this.selection.end !== false && this.selection.end !== selectionEnd);

    //   if (
    //     update &&
    //     this.element.current.selectionStart &&
    //     this.element.current.selectionEnd
    //   ) {
    //     this.element.current.selectionStart = this.selection.start;
    //     this.element.current.selectionEnd = this.selection.end;
    //   }
    // }

    if (prevProps.focus !== this.props.focus && this.props.focus) {
      this.setFocus();
    }

    if (
      prevProps.error !== this.props.error &&
      this.props.error !== prevState.error
    ) {
      this.setState({ hasError: this.props.error });
    }

    if (prevProps.value !== this.props.value) {
      this.onChange(null, this.props.value, false);
    }
  }

  componentWillUnmount() {
    if (this.element && this.element.current) {
      this.element.current.removeEventListener(
        'keyup',
        this.handleKeyup,
        false
      );
    }
  }

  handleKeyup = event => {
    this.setState({ touched: true });
  };

  onBlur = event => {
    if (!event) return;
    if (event && event.persist) event.persist();

    const { name, type, onBlur } = this.props;
    const value = event && event.target.value;

    if (onBlur) onBlur(event, name, value, type);
  };

  onFocus = event => {
    if (!event) return;
    if (event && event.persist) event.persist();

    const { name, type, onFocus } = this.props;
    const value = event && event.target.value;

    if (onFocus) onFocus(event, name, value, type);
  };

  setFocus = () => {
    const { focus, delay } = this.props;
    let time = focus && focus === 'delayed' ? (delay ? delay : 3000) : 300;

    if (focus) {
      setTimeout(() => {
        const $element = this.element.current;
        if ($element && $element.focus) {
          $element.focus();
          // $element.click();
        }
      }, time);
    }
  };

  onKeyUp = event => {
    if (
      this.element.current.selectionStart &&
      this.element.current.selectionEnd
    ) {
      this.selection = {
        start: this.element.current.selectionStart,
        end: this.element.current.selectionEnd,
      };
    }
  };

  onChange = (event, defaultValue = '', emit = true) => {
    if (event && event.persist) event.persist();
    const { name, validation, type } = this.props;

    const value = (event && event.target && event.target.value) || defaultValue;
    let hasError = false;

    if (validation) {
      hasError = validation(name, value, type);
    }

    this.setState(
      {
        hasError,
      },
      () => {
        if (emit) this.callback(event, value);
      }
    );
  };

  callback = (event, value) => {
    const { name, type } = this.state;
    const { callback } = this.props;

    if (callback) callback(event, name, value, type);
  };

  getPlaceholder = () => {
    const { placeholder } = this.props;
    return placeholder || undefined;
  };

  getInputClassNames = () => {
    const { type, inputClassNames } = this.props;

    return clsx(
      _CLASSES.INPUT.MAIN,
      type && type === 'password' && _CLASSES.INPUT.PASSWORD,
      type && type === 'textarea' && _CLASSES.INPUT.TEXTAREA,
      inputClassNames
    );
  };

  getLabelClassNames = () => {
    const { label } = this.props;
    return clsx(_CLASSES.LABEL.MAIN, !label && _CLASSES.LABEL.HIDDEN);
  };

  getLabelTitleClassNames = () => {
    return clsx(_CLASSES.LABEL.TITLE);
  };

  getErrorClassNames = () => {
    return clsx(_CLASSES.VALIDATION.ERROR);
  };

  getSuccessClassNames = () => {
    return clsx(_CLASSES.VALIDATION.SUCCESS);
  };

  getInstructionsClassNames = () => {
    return clsx(_CLASSES.INSTRUCTIONS);
  };

  getStyleClassNames = () => {
    const {
      value,
      disabled,
      readonly,
      className,
      placeholder,
      theme,
    } = this.props;
    const { hasError } = this.state;

    return clsx(
      _CLASSES.MAIN,
      disabled && _CLASSES.DISABLED,
      readonly && _CLASSES.READONLY,
      !value && _CLASSES.EMPTY,
      hasError && _CLASSES.ERROR,
      !hasError && value && _CLASSES.SUCCESS,
      placeholder && _CLASSES.PLACEHOLDER,
      theme && _CLASSES[theme.toUpperCase()],
      className
    );
  };
}

const requiredPropsCheck = (props, propName, componentName) => {
  if (
    !props.value &&
    props.value !== '' &&
    !props.placeholder &&
    props.placeholder !== ''
  ) {
    return new Error(
      `At least one of the props 'value' or 'placeholder' is required by '${componentName}' component.`
    );
  }
};

AcTextInputController.propTypes = {
  callback: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  type: PropTypes.oneOf([
    'text',
    'number',
    'tel',
    'email',
    'date',
    'password',
    'textarea',
  ]),
  disabled: PropTypes.bool,
  readonly: PropTypes.bool,
  validation: PropTypes.func,
  autocomplete: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  instructions: PropTypes.string,
  error: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  value: requiredPropsCheck,
  placeholder: requiredPropsCheck,
  indicator: PropTypes.bool,
  withSuccess: PropTypes.bool,
};

AcTextInputController.defaultProps = {
  type: 'text',
  autocomplete: false,
  disabled: false,
  readonly: false,
  indicator: false,
  withSuccess: true,
};

export default AcTextInputController;
