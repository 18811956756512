// Imports => React
import React, { useEffect, useState, useCallback, useMemo, memo } from 'react';
import { Fade } from 'react-reveal';
import clsx from 'clsx';

// Imports => Constants
import { KEYS, TYPES } from '@constants';

// Imports => Atoms
import AcTextInput from '@atoms/ac-text-input/ac-text-input.web';

const _CLASSES = {
	MAIN: 'ac-pin',
	INPUT: 'ac-pin__input',
	ERROR: 'ac-pin--error',
	LOADING: 'ac-pin--loading',
	MASK: {
		MAIN: 'ac-pin__mask',
		DOT: 'ac-pin__dot',
		FILLED: 'ac-pin__dot--filled',
		FOCUSED: 'ac-pin__dot--focused',
	},
};

let initTimeout = null;
let resetTimeout = null;

const AcPinInput = ({
	max = 5,
	attempts = 0,
	max_attempts = 3,
	name = KEYS.CODE,
	error = null,
	errorMessage = null,
	loading = false,
	loaded = false,
	locked = false,
	callback = () => {},
	input = { id: 'ac-pin-input' },
	className,
}) => {
	const [code, setCode] = useState('');
	const [focus, setFocus] = useState(false);

	useEffect(() => {
		window.addEventListener('resize', handleResize, false);

		return () => {
			if (initTimeout) clearTimeout(initTimeout);
			window.removeEventListener('resize', handleResize, false);
		};
	}, []);

	useEffect(() => {
		if (resetTimeout) clearTimeout(resetTimeout);
		resetTimeout = setTimeout(() => {
			setCode('');
		}, 1000);
	}, [attempts, error]);

	useEffect(() => {
		if (resetTimeout) clearTimeout(resetTimeout);
		resetTimeout = setTimeout(() => {
			setCode('');
		}, 400);
	}, [name]);

	const handleResize = () => {
		setTimeout(() => {
			if (window && window.scrollTo) window.scrollTo(0, 0);
		}, 50);
	};

	const handleFocus = () => {
		setFocus(true);
	};

	const handleBlur = () => {
		setFocus(false);
	};

	const handleSubmit = useCallback(
		async value => {
			if (loading) return;
			if (callback) {
				await callback(value, name);
				handleClick();
			}
		},
		[name]
	);

	const handleClick = useCallback(() => {
		const $field = document.querySelector('input[type="number"]');

		if ($field) {
			if ($field.focus) $field.focus();
			if ($field.click) $field.click();
		}
	}, []);

	const handleInputChange = (event, name, value, type) => {
		if (event && event.persist) event.persist();

		if (value.length <= max) {
			setCode(value);

			if (value.length === max) {
				handleSubmit(value);
			}
		}
	};

	const getMainClassNames = useMemo(() => {
		return clsx(
			_CLASSES.MAIN,
			((errorMessage && attempts > 0) || error) && _CLASSES.ERROR,
			loading && _CLASSES.LOADING,
			className
		);
	}, [loading, error, errorMessage, attempts, className]);

	const getMaskClassNames = useMemo(() => {
		return clsx(_CLASSES.MASK.MAIN);
	}, []);

	const getDotClassNames = useCallback((filled, focused) => {
		return clsx(
			_CLASSES.MASK.DOT,
			filled && _CLASSES.MASK.FILLED,
			focused && _CLASSES.MASK.FOCUSED
		);
	}, []);

	const hasAttempted = useMemo(() => {
		return attempts > 0 || !!locked;
	}, [attempts, max_attempts, error, locked]);

	const renderDots = useMemo(() => {
		const code_len = code ? code.length : 0;
		const len = max;
		let n = 1;
		let result = [];

		for (n; n <= len; n++) {
			const filled = n <= code_len;
			const focused = focus && n + 1 > code_len;
			const obj = (
				<span
					key={`ac-pin-dot--${n}`}
					className={getDotClassNames(filled, focused)}
				/>
			);
			result.push(obj);
		}

		return result;
	}, [code, focus, error, getDotClassNames]);

	const getInputOptions = useMemo(() => {
		const disabled = locked || loading;

		return {
			id: input.id,
			type: TYPES.NUMBER,
			name,
			value: code,
			required: true,
			disabled,
			focus: 'delayed',
			delay: loaded ? 3000 : 1000,
			pattern: '\\d*',
			autoComplete: 'new-password',
			callback: handleInputChange,
			onFocus: handleFocus,
			onBlur: handleBlur,
			maxLength: max,
		};
	}, [code, name, attempts, loading, loaded]);

	const renderError = useMemo(() => {
		let msg = errorMessage || `Pincode is onjuist.`;

		return (
			<p
				className={
					'h-margin-bottom-0 h-text--size-smaller h-text--align-center h-text--color-error'
				}
				style={{ lineHeight: 1.5 }}
				dangerouslySetInnerHTML={{
					__html: msg,
				}}
			/>
		);
	}, [attempts, errorMessage]);

	return (
		<div className={getMainClassNames}>
			<AcTextInput {...getInputOptions} />

			<div className={getMaskClassNames} onClick={handleClick}>
				{renderDots}
			</div>

			<Fade
				duration={300}
				bottom
				collapse
				when={(hasAttempted && error) || errorMessage}
			>
				{renderError}
			</Fade>
		</div>
	);
};

export default memo(AcPinInput);
