import React, { useEffect, useState, useMemo, memo } from 'react';

// Imports => Constants
import { THEMES } from '@constants';

// Imports => Atoms
import { AcContainer, AcRow, AcColumn } from '@atoms/ac-grid';
import AcHeading from '@atoms/ac-heading/ac-heading.web';
import AcRichContent from '@atoms/ac-rich-content/ac-rich-content.web';
import AcAnswerCard from '@atoms/ac-answer-card/ac-answer-card.web';

let delay = null;

const AcQuestionSelect = ({
	id,
	title,
	question,
	answers,
	value = null,
	callback,
}) => {
	const [selection, setSelection] = useState(value);

	useEffect(() => {}, []);

	const handleSetAnswer = value => {
		if (delay) clearTimeout(delay);

		setSelection(value);

		delay = setTimeout(() => {
			handleFormSubmit(value);
		}, 500);
	};

	const handleFormSubmit = value => {
		if (callback) callback(id, value);
	};

	const renderAnswers = useMemo(() => {
		const len = answers.length;
		let n = 0;
		let result = [];

		for (n; n < len; n++) {
			const q = answers[n];
			const { id, text } = q;

			const object = (
				<AcColumn xs={6} key={`select-answer-${id}`}>
					<AcAnswerCard
						selected={selection === text}
						callback={() => handleSetAnswer(text)}
					>
						{text}
					</AcAnswerCard>
				</AcColumn>
			);

			result.push(object);
		}

		return result;
	}, [answers, selection, handleSetAnswer]);

	return (
		<AcContainer
			className={'h-text--align-center h-margin-top-0 h-padding-top-60'}
		>
			{title && (
				<AcRow>
					<AcColumn>
						<AcHeading rank={3} theme={THEMES.WHITE}>
							{title}
						</AcHeading>
					</AcColumn>
				</AcRow>
			)}

			{question && (
				<AcRow>
					<AcColumn>
						<AcRichContent
							content={`<p class="h-text-body-large">${question}</p>`}
							className={'h-text--color-white'}
						/>
					</AcColumn>
				</AcRow>
			)}

			<AcRow className={'h-margin-top-40'} noGutters>
				{renderAnswers}
			</AcRow>

			<div style={{ height: '10rem' }} />
		</AcContainer>
	);
};

export default memo(AcQuestionSelect);
