import React, { useMemo, memo } from 'react';
import CountUp from 'react-countup';
import clsx from 'clsx';

// Imports => Utils
import { AcSaveState, AcGetState } from '@utils';

// Imports => Atoms
import AcIcon from '@atoms/ac-icon/ac-icon.web';

const _CLASSES = {
	MAIN: 'ac-kpi',
	HORIZONTAL: 'ac-kpi--horizontal',
	LARGE: 'ac-kpi--large',
	ICON: 'ac-kpi__icon',
	LABEL: 'ac-kpi__label',
	VALUE: 'ac-kpi__value',
};

const AcKpi = ({
	label,
	value = 0,
	icon,
	horizontal = false,
	large = false,
}) => {
	const getStartValue = () => {
		const start = AcGetState(`ac-kpi-${label}`);

		return start ? start : 0;
	};

	const handleComplete = () => {
		AcSaveState(`ac-kpi-${label}`, value);
	};

	const getValueClassNames = useMemo(() => {
		return clsx(_CLASSES.VALUE);
	}, []);

	const getLabelClassNames = useMemo(() => {
		return clsx(_CLASSES.LABEL);
	}, []);

	const getIconClassNames = useMemo(() => {
		return clsx(_CLASSES.ICON);
	}, []);

	const getMainClassNames = useMemo(() => {
		return clsx(
			_CLASSES.MAIN,
			horizontal && _CLASSES.HORIZONTAL,
			large && _CLASSES.LARGE
		);
	}, [horizontal, large]);

	const getCountUpOptions = useMemo(() => {
		const start = getStartValue();

		return {
			start,
			end: value,
			duration: start !== 0 ? 0.5 : 2,
			delay: 0,
			preserveValue: true,
			easingFn: (t, b, c, d) => {
				return c * (t /= d) * t * t + b;
			},
			onEnd: handleComplete,
		};
	}, [value]);

	return (
		<div className={getMainClassNames}>
			{icon && <AcIcon icon={icon} className={getIconClassNames} />}
			{value && (
				<div className={getValueClassNames}>
					<CountUp {...getCountUpOptions} />
				</div>
			)}
			{label && <div className={getLabelClassNames}>{label}</div>}
		</div>
	);
};

export default memo(AcKpi);
