import React, { useEffect, useState, useMemo } from 'react';
import { withRouter } from 'react-router-dom';
import { Fade } from 'react-reveal';

// Imports => Constants
import { ICONS, ROUTES, THEMES, TYPES, VARIANTS } from '@constants';

// Imports => Utilities
import { AcIsEmptyString, AcIsSet } from '@utils';

// Imports => Atoms
import { AcContainer, AcRow, AcColumn } from '@atoms/ac-grid';
import AcCard from '@atoms/ac-card/ac-card.web';
import AcBottomDrawer from '@atoms/ac-bottom-drawer/ac-bottom-drawer.web';
import AcHeading from '@atoms/ac-heading/ac-heading.web';
import AcRichContent from '@atoms/ac-rich-content/ac-rich-content.web';
import AcIcon from '@atoms/ac-icon/ac-icon.web';
import AcButton from '@atoms/ac-button/ac-button.web';
import AcSliderInput from '@atoms/ac-slider-input/ac-slider-input.web';

let delay = null;

const AcQuestionSmiley = ({
	id,
	title,
	question,
	answers,
	message = '',
	value = 3,
	change,
	callback,
	history,
	match,
}) => {
	const [selection, setSelection] = useState(value);
	const [drawerVisible, setDrawerVisible] = useState(false);

	const showDrawer = () => {
		setDrawerVisible(true);
	};

	const hideDrawer = () => {
		setDrawerVisible(false);
	};

	useEffect(() => {
		if (delay) clearTimeout(delay);
		delay = setTimeout(() => {
			if (selection) showDrawer();
			else hideDrawer();
		}, 600);
	}, [selection]);

	const handleDisplayCommentModal = () => {
		if (!match || !match.params || !match.params.id) return;

		const { replace } = history;
		if (replace) replace(ROUTES.COMMENT.path.replace(':id', match.params.id));
	};

	const handleSetAnswer = value => {
		setSelection(value);
		if (change) change(id, selection);
	};

	const handleFormSubmit = async event => {
		if (event && event.preventDefault) event.preventDefault();

		if (callback) callback(id, selection);
	};

	const getSubmitButtonOptions = useMemo(() => {
		return {
			type: TYPES.BUTTON,
			theme: THEMES.MINTY,
			title: 'Bevestig',
			callback: handleFormSubmit,
		};
	}, [selection]);

	const getAddCommentButtonOptions = useMemo(() => {
		return {
			type: TYPES.BUTTON,
			theme: THEMES.WHITE,
			variant: VARIANTS.OUTLINE,
			callback: handleDisplayCommentModal,
			className: 'h-margin-y-0',
		};
	}, []);

	const renderSlider = useMemo(() => {
		return <AcSliderInput value={selection} callback={handleSetAnswer} />;
	}, [selection, handleSetAnswer]);

	return (
		<>
			<AcContainer
				className={'h-text--align-center h-margin-top-0 h-padding-top-60'}
			>
				{title && (
					<AcRow>
						<AcColumn>
							<AcHeading rank={3} theme={THEMES.WHITE}>
								{title}
							</AcHeading>
						</AcColumn>
					</AcRow>
				)}

				{question && (
					<AcRow>
						<AcColumn>
							<AcRichContent
								content={`<p class="h-text-body-large">${question}</p>`}
								className={'h-text--color-white'}
							/>
						</AcColumn>
					</AcRow>
				)}

				<AcRow className={'h-margin-top-20 h-margin-bottom-60'} noGutters>
					{renderSlider}
				</AcRow>

				<AcRow>
					<AcColumn>
						<Fade
							bottom
							collapse
							distance={'20px'}
							duration={300}
							when={!AcIsSet(message) || AcIsEmptyString(message)}
						>
							<AcButton {...getAddCommentButtonOptions}>
								<span>
									<AcIcon icon={ICONS.PLUS} /> Opmerking
								</span>
							</AcButton>
						</Fade>

						<Fade
							bottom
							collapse
							distance={'20px'}
							duration={300}
							when={AcIsSet(message) && !AcIsEmptyString(message)}
						>
							<AcCard
								className={'ac-comment-card'}
								callback={handleDisplayCommentModal}
							>
								<AcRichContent
									content={`<p class="h-text-body-large">${message}</p>`}
								/>
								<AcIcon
									icon={ICONS.EDIT_OUTLINE}
									className={'ac-comment-icon'}
								/>
							</AcCard>
						</Fade>
					</AcColumn>
				</AcRow>

				<div style={{ height: '10rem' }} />
			</AcContainer>

			<AcBottomDrawer visible={drawerVisible} theme={THEMES.OMEGA}>
				<AcContainer fluid className={'h-text--align-center'}>
					<AcRow>
						<AcColumn>
							<AcButton {...getSubmitButtonOptions}>
								<span>Bevestig</span>
							</AcButton>
						</AcColumn>
					</AcRow>
				</AcContainer>
			</AcBottomDrawer>
		</>
	);
};

export default withRouter(AcQuestionSmiley);
