import React, { useEffect, useState, useMemo, memo } from 'react';

// Imports => Constants
import { THEMES } from '@constants';

// Imports => Utilities
import { AcUUID, AcSupportsWEBP } from '@utils';

// Imports => Atoms
import { AcContainer, AcRow, AcColumn } from '@atoms/ac-grid';
import AcHeading from '@atoms/ac-heading/ac-heading.web';
import AcRichContent from '@atoms/ac-rich-content/ac-rich-content.web';
import AcAnswerCard from '@atoms/ac-answer-card/ac-answer-card.web';

const _answers = [
	{
		id: AcUUID(),
		num: 1,
		text: 'Zeer grote kans',
		visual: 'u1F60E',
	},
	{
		id: AcUUID(),
		num: 2,
		text: 'Kans zit erin',
		visual: 'u1F913',
	},
	{
		id: AcUUID(),
		num: 3,
		text: 'Neutraal',
		visual: 'u1F610',
	},
	{
		id: AcUUID(),
		num: 4,
		text: 'Kleine kans',
		visual: 'u1F928',
	},
	{
		id: AcUUID(),
		num: 5,
		text: 'Nul kans',
		visual: 'u1F623',
	},
];

let delay = null;

const AcQuestionScale = ({
	id,
	title,
	question,
	value = null,
	answers = _answers,
	callback,
}) => {
	const [selection, setSelection] = useState(value);

	useEffect(() => {}, []);

	const handleSetAnswer = value => {
		if (delay) clearTimeout(delay);

		setSelection(value);

		delay = setTimeout(() => {
			handleFormSubmit(value);
		}, 500);
	};

	const handleFormSubmit = value => {
		if (callback) callback(id, value);
	};

	const renderAnswers = useMemo(() => {
		const len = answers.length;
		let n = 0;
		let result = [];

		const supportsWEBP = AcSupportsWEBP();

		for (n; n < len; n++) {
			const q = answers[n];
			const { id, text, visual, num } = q;

			const source = supportsWEBP ? `${visual}.webp` : `${visual}.png`;

			console.log('visual', visual);
			console.log('source', source);

			const img = require(`@assets/images/${source}`);

			const object = (
				<AcColumn xs={12} key={`scale-answer-${id}`}>
					<AcAnswerCard
						selected={selection === num}
						callback={() => handleSetAnswer(num)}
						horizontal
					>
						<img
							src={img}
							width="40"
							height="40"
							className={'ac-image--tiny h-margin-right-25'}
							alt={'scale-answer-icon'}
						/>
						{text}
					</AcAnswerCard>
				</AcColumn>
			);

			result.push(object);
		}

		return result;
	}, [answers, selection, handleSetAnswer]);

	return (
		<AcContainer
			className={'h-text--align-center h-margin-top-0 h-padding-top-60'}
		>
			{title && (
				<AcRow>
					<AcColumn>
						<AcHeading rank={3} theme={THEMES.WHITE}>
							{title}
						</AcHeading>
					</AcColumn>
				</AcRow>
			)}

			{question && (
				<AcRow>
					<AcColumn>
						<AcRichContent
							content={`<p class="h-text-body-large">${question}</p>`}
							className={'h-text--color-white'}
						/>
					</AcColumn>
				</AcRow>
			)}

			<AcRow className={'h-margin-top-40'} noGutters>
				{renderAnswers}
			</AcRow>

			<div style={{ height: '10rem' }} />
		</AcContainer>
	);
};

export default memo(AcQuestionScale);
