import React, { useCallback, useMemo, memo } from 'react';
import clsx from 'clsx';

// Imports => Constants
import { ICONS, THEMES } from '@constants';

// Imports => Utilities
import { AcIsSet } from '@utils';

// Imports => Atoms
import { AcContainer, AcRow, AcColumn } from '@atoms/ac-grid';
import AcRichContent from '@atoms/ac-rich-content/ac-rich-content.web';
import AcDivider from '@atoms/ac-divider/ac-divider.web';
import AcIcon from '@atoms/ac-icon/ac-icon.web';

import Door from '@assets/images/door.svg'
import Gps from '@assets/images/gps.svg'

const _CLASSES = {
	MAIN: 'ac-action-introduction',
	POINTER: 'ac-action-introduction__pointer',
	BUBBLE: 'ac-action-introduction__bubble',
	CONTENT: 'ac-action-introduction__content',
	INITIALS: 'ac-action-introduction__initials',
	NAME: 'ac-action-introduction__name',
	LOCATION: {
		MAIN: 'ac-action-location',
		DETAILS: 'ac-action-location__details',
		ICON: 'ac-action-location__icon',
		LINK: 'ac-action-location__link',
	},
};

const AcActionIntroduction = ({ type, content, location, chief, className }) => {
	const getLocationLinkClassNames = useMemo(() => {
		return clsx(_CLASSES.LOCATION.LINK);
	}, []);

	const getLocationIconClassNames = useMemo(() => {
		return clsx(_CLASSES.LOCATION.ICON);
	}, []);

	const getLocationDetailsClassNames = useMemo(() => {
		return clsx(_CLASSES.LOCATION.DETAILS);
	}, []);

	const getLocationClassNames = useMemo(() => {
		return clsx(_CLASSES.LOCATION.MAIN);
	}, []);

	const getInitialsClassNames = useMemo(() => {
		return clsx(_CLASSES.INITIALS);
	}, []);

	const getNameClassNames = useMemo(() => {
		return clsx(_CLASSES.NAME, 'h-text--tt-none');
	}, []);

	const getContentClassNames = useMemo(() => {
		return clsx(_CLASSES.CONTENT);
	}, []);

	const getBubbleClassNames = useMemo(() => {
		return clsx(_CLASSES.BUBBLE);
	}, []);

	const getPointerClassNames = useMemo(() => {
		return clsx(_CLASSES.POINTER);
	}, []);

	const getMainClassNames = useMemo(() => {
		return clsx(_CLASSES.MAIN);
	}, []);

	const getInitials = useCallback(
		name => {
			if (!name) return null;

			const arr = name.split(' ');
			const first_letter = arr[0][0];
			const last_letter = arr.length > 1 ? arr[arr.length - 1][0] : arr[0][1];

			return `${first_letter}${last_letter}`;
		},
		[chief]
	);

	const hasLocation = useMemo(() => {
		return AcIsSet(location) && AcIsSet(location.address);
	}, [location]);

	const handleOpenLocation = event => {
		if (event && event.preventDefault) event.preventDefault();

		if (!hasLocation) return null;

		const { address } = location;

		const isIOS =
			navigator.platform.indexOf('iPhone') !== -1 ||
			navigator.platform.indexOf('iPad') !== -1 ||
			navigator.platform.indexOf('iPod') !== -1;

		let query = `?q=${encodeURIComponent(address)}`;
		let application = isIOS ? 'apple' : 'google';
		query = `http://maps.${application}.com/maps/${query}`;

		window.open(query, '_system');
	};

	return (
		<div className={getMainClassNames}>
			<div className={getBubbleClassNames}>
				<div className={getPointerClassNames} />
				<AcContainer fluid>
					<AcRow noGutters>
						{chief && (
							<AcColumn>
								<div className={'ac-action-introduction__details'}>
									<div className={getInitialsClassNames}>
										{getInitials(chief)}
									</div>
									<div className={getNameClassNames}>{chief}</div>
								</div>
							</AcColumn>
						)}
					</AcRow>

					<AcRow noGutters>
						<AcColumn>
							<AcRichContent
								content={`<p>${content}</p>`}
								className={getContentClassNames}
							/>
						</AcColumn>
					</AcRow>

					{location && <AcDivider theme={THEMES.PITCH} />}

					{location && (
						<div className={getLocationClassNames}>
							<AcRow noGutters>
								<AcColumn>
									<div className={getLocationDetailsClassNames} style={{ paddingBottom: '16px' }}>
										{type === 'door-to-door' ? <Door className="ac-svg-icon" /> : <Gps className="ac-svg-icon" />}
										<p style={{ fontWeight: 'normal' }}>
											{type === 'door-to-door' ? 'Langs de deuren' : 'Gesprek op één plek'}
										</p>
									</div>
								</AcColumn>
							</AcRow>
							<AcRow noGutters>
								<AcColumn>
									<div className={getLocationDetailsClassNames}>
										<AcIcon
											icon={ICONS.GROUPS_OUTLINE}
											className={getLocationIconClassNames}
										/>
										<p>Verzamellocatie:</p>
										<p
											dangerouslySetInnerHTML={{
												__html: location.address.split(',').join('<br/>'),
											}}
										/>
									</div>
								</AcColumn>
							</AcRow>

							{hasLocation && (
								<AcRow noGutters>
									<AcColumn>
										<div
											className={getLocationLinkClassNames}
											onClick={handleOpenLocation}
										>
											<span>Bekijk locatie</span>
										</div>
									</AcColumn>
								</AcRow>
							)}
						</div>
					)}
				</AcContainer>
			</div>
		</div>
	);
};

export default memo(AcActionIntroduction);
