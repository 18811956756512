import React, { useMemo, memo } from 'react';
import { withRouter } from 'react-router-dom';
import clsx from 'clsx';

// Imports => Contants
import { ICONS } from '@constants';

// Imports => atoms
import AcIcon from '@atoms/ac-icon/ac-icon.web';

const _CLASSES = {
	MAIN: 'ac-back-button',
	WHITE: 'ac-back-button--white',
	OMEGA: 'ac-back-button--omega',
	LIGHT: 'ac-back-button--light',
	ICON: 'ac-back-button__icon',
};

const AcBackButton = ({ history, callback, theme }) => {
	const handleClick = () => {
		if (callback) {
			callback();
		} else {
			const { goBack } = history;

			if (goBack) goBack();
		}
	};

	const getIconClassNames = useMemo(() => {
		return clsx(_CLASSES.ICON);
	}, []);

	const getMainClassNames = useMemo(() => {
		return clsx(_CLASSES.MAIN, theme && _CLASSES[theme.toUpperCase()]);
	}, [theme]);

	return (
		<div className={getMainClassNames} onClick={handleClick}>
			<AcIcon icon={ICONS.WEST} className={getIconClassNames} />
		</div>
	);
};

export default withRouter(memo(AcBackButton));
