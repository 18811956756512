// Imports => React
import React, { useCallback, useMemo, memo } from 'react';
import clsx from 'clsx';

// Imports => Config
import config from '@config';

// Imports => Constants
import { DEFAULT_ROUTE } from '@constants';

// Imports => Utilities
import { AcSupportsWEBP } from '@utils';

const _CLASSES = {
	MAIN: 'ac-logo',
	LIGHT: 'ac-logo--light',
	DARK: 'ac-logo--dark',
	ALPHA: 'ac-logo--alpha',
	CONTAINER: {
		MAIN: 'ac-logo__container',
	},
	VISUAL: {
		MAIN: 'ac-logo__visual',
	},
};

// Component
const AcLogo = ({ callback }) => {
	const getContainerClassNames = useCallback(() => {
		return clsx(_CLASSES.CONTAINER.MAIN);
	}, []);

	const getStyleClassNames = useCallback(() => {
		return clsx(_CLASSES.MAIN);
	}, []);

	const renderLogo = useMemo(() => {
		const source = AcSupportsWEBP()
			? 'vvd-logo-02b-1x.webp'
			: 'vvd-logo-02b-1x.png';
		const img = require(`@assets/images/${source}`);

		return <img src={img} alt={'VVD Logo Diap 1200DPI'} />;
	});

	return (
		<a
			href={DEFAULT_ROUTE.path}
			className={getStyleClassNames()}
			onClick={callback}
			title={config.appName}
		>
			<div className={getContainerClassNames()}>{renderLogo}</div>
		</a>
	);
};

export default memo(AcLogo);
