import React, { useEffect, useRef, useMemo } from 'react';
import { withRouter } from 'react-router-dom';
import { inject } from 'mobx-react';
import { observer } from 'mobx-react-lite';
import clsx from 'clsx';

// Imports => Constants
import { ICONS, KEYS, ROUTES, THEMES } from '@constants';

// Imports => Atoms
import AcIcon from '@atoms/ac-icon/ac-icon.web';
import AcRipple from '@atoms/ac-ripple/ac-ripple.web';

const _CLASSES = {
	MAIN: 'ac-navigation-wrp',
	VISIBLE: 'ac-navigation-wrp--visible',
	HAMBURGER: {
		MAIN: 'ac-hamburger',
		DOTS: 'ac-hamburger__dots',
		DOT: 'ac-hamburger__dot',
	},
	NAVIGATION: {
		MAIN: 'ac-navigation',
		LIST: 'ac-navigation__list',
		ITEM: 'ac-navigation__item',
		LINK: 'ac-navigation__link',
		ACTIVE: 'ac-navigation__link--active',
		ICON: 'ac-navigation__icon',
	},
};

const AcNavigation = ({ history, store }) => {
	const $navigation = useRef(null);

	useEffect(() => {
		addEvents();

		return () => removeEvents();
	}, []);

	const addEvents = () => {
		document.addEventListener('click', handleClick, false);
	};

	const removeEvents = () => {
		document.removeEventListener('click', handleClick, false);
	};

	const handleClick = event => {
		if (event && event.persist) event.persist();
		if (event && event.target) {
			const $element = $navigation.current;

			if ($element) {
				const inside = $element.contains(event.target);

				if (!inside) hide();
			}
		}
	};

	const show = () => {
		const { visible } = store.ui.navigation;
		if (!visible) store.ui.setValue(KEYS.NAVIGATION, KEYS.VISIBLE, true);
	};

	const hide = () => {
		const { visible } = store.ui.navigation;
		if (visible) store.ui.setValue(KEYS.NAVIGATION, KEYS.VISIBLE, false);
	};

	const handleLogout = event => {
		if (event && event.preventDefault) event.preventDefault();
		if (!store.auth.is_authorized) return;

		hide();

		store.auth.logout();
	};

	const handleSettings = event => {
		if (event && event.preventDefault) event.preventDefault();
		if (!store.auth.is_authorized) return;

		hide();

		const { push } = history;
		if (push) push(ROUTES.SETTINGS.path);
	};

	const getIconClassNames = useMemo(() => {
		return clsx(_CLASSES.NAVIGATION.ICON);
	}, []);

	const getLinkClassNames = useMemo(() => {
		return clsx(_CLASSES.NAVIGATION.LINK);
	}, []);

	const getItemClassNames = useMemo(() => {
		return clsx(_CLASSES.NAVIGATION.ITEM);
	}, []);

	const getListClassNames = useMemo(() => {
		return clsx(_CLASSES.NAVIGATION.LIST);
	}, []);

	const getNavigationClassNames = useMemo(() => {
		return clsx(_CLASSES.NAVIGATION.MAIN);
	}, []);

	const getDotClassNames = useMemo(() => {
		return clsx(_CLASSES.HAMBURGER.DOT);
	}, []);

	const getDotsClassNames = useMemo(() => {
		return clsx(_CLASSES.HAMBURGER.DOTS);
	}, []);

	const getHamburgerClassNames = useMemo(() => {
		return clsx(_CLASSES.HAMBURGER.MAIN);
	}, []);

	const getMainClassNames = useMemo(() => {
		const { visible } = store.ui.navigation;

		return clsx(_CLASSES.MAIN, visible && _CLASSES.VISIBLE);
	}, [store.ui.navigation.visible]);

	return (
		<div className={getMainClassNames} ref={$navigation}>
			<div className={getHamburgerClassNames} onClick={show}>
				<AcRipple theme={THEMES.WHITE} simple />
				<div className={getDotsClassNames}>
					<div className={getDotClassNames} />
					<div className={getDotClassNames} />
					<div className={getDotClassNames} />
				</div>
			</div>

			<nav className={getNavigationClassNames}>
				<ul className={getListClassNames}>
					<li className={getItemClassNames}>
						<div className={getLinkClassNames} onClick={handleSettings}>
							<AcIcon
								icon={ICONS.CHEVRON_RIGHT}
								className={getIconClassNames}
							/>
							Instellingen
						</div>
					</li>
					<li className={getItemClassNames}>
						<div className={getLinkClassNames} onClick={handleLogout}>
							<AcIcon
								icon={ICONS.CHEVRON_RIGHT}
								className={getIconClassNames}
							/>
							Uitloggen
						</div>
					</li>
				</ul>
			</nav>
		</div>
	);
};

export default withRouter(inject('store')(observer(AcNavigation)));
