// Imports => React
import React, { useMemo } from 'react';
import { inject } from 'mobx-react';
import { observer } from 'mobx-react-lite';
import clsx from 'clsx';

// Imports => Atoms
import { AcContainer, AcRow, AcColumn } from '@atoms/ac-grid';
import AcPinInput from '@atoms/ac-pin-input/ac-pin-input.web';

const _CLASSES = {
	MAIN: 'ac-pin-confirm-modal',
	CONTENT: 'ac-pin-confirm-modal__content',
};

const AcPinConfirmModal = ({ store, callback }) => {
	const { is_loading } = store.profile;

	const handleCallback = value => {
		if (callback) callback(value);
	};

	const getContentClassNames = useMemo(() => {
		return clsx([_CLASSES.CONTENT]);
	}, []);

	const getStyleClassNames = useMemo(() => {
		return clsx([_CLASSES.MAIN]);
	}, []);

	return (
		<div className={getStyleClassNames}>
			<div className={getContentClassNames}>
				<AcContainer fluid>
					<AcRow>
						<AcColumn>
							<AcPinInput
								callback={handleCallback}
								loading={is_loading}
								error={store.profile.current_error}
								attempts={store.profile.current_error ? 1 : 0}
								className={'h-margin-y-10'}
							/>
						</AcColumn>
					</AcRow>
				</AcContainer>
			</div>
		</div>
	);
};

export default inject('store')(observer(AcPinConfirmModal));
