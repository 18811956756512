import React, { useEffect, useState, useMemo, memo } from 'react';
import CountUp from 'react-countup';
import clsx from 'clsx';

const _CLASSES = {
	MAIN: 'ac-action-progress',
	INITIAL: 'ac-action-progress--initial',
	BAR: 'ac-action-progress__bar',
	TRACKER: 'ac-action-progress__tracker',
	KNOB: 'ac-action-progress__knob',
	VALUE: 'ac-action-progress__value',
};

let _delay = null;

const AcActionProgress = ({
	start = 0,
	progress = 0,
	max = 10,
	onComplete,
	initial = false,
}) => {
	const [ready, setReady] = useState(false);
	const [position, setPosition] = useState(0);

	const calculateInitialProgress = () => {
		setReady(false);

		let proc = (start / max) * 100;
		proc = proc > 100 ? 100 : proc < 0 ? 0 : proc;

		setPosition(proc);

		_delay = setTimeout(() => {
			setReady(true);
		}, 100);

		_delay = setTimeout(() => {
			init();
		}, 200);
	};

	const handleComplete = () => {
		setReady(true);
		if (onComplete) onComplete();
	};

	const getValueClassNames = useMemo(() => {
		return clsx(_CLASSES.VALUE);
	}, []);

	const getKnobClassNames = useMemo(() => {
		return clsx(_CLASSES.KNOB);
	}, []);

	const getTrackerClassNames = useMemo(() => {
		return clsx(_CLASSES.TRACKER);
	}, []);

	const getBarClassNames = useMemo(() => {
		return clsx(_CLASSES.BAR);
	}, []);

	const getMainClassNames = useMemo(() => {
		return clsx(_CLASSES.MAIN, !ready && _CLASSES.INITIAL);
	}, [ready, start]);

	const calculateProgress = useMemo(() => {
		let proc = (progress / max) * 100;
		proc = proc > 100 ? 100 : proc < 0 ? 0 : proc;

		return proc;
	}, [progress, max]);

	const getTrackerPosition = useMemo(() => {
		let pos = position;

		return { transform: `scaleX(${pos / 100})` };
	}, [position]);

	const getKnobPosition = useMemo(() => {
		const $bar = document.getElementById('ac-progress-bar');
		const $knob = document.getElementById('ac-progress-knob');

		let pos = 0;

		if ($bar && $knob) {
			const bar_width = $bar.offsetWidth;
			const knob_width = $knob.offsetWidth;
			const knob_center = $knob.offsetWidth / 3;

			pos = (bar_width - knob_width) * (position / 100);

			if (pos - knob_center < 0) {
				pos = 0;
			} else if (pos > bar_width - knob_width) {
				pos = bar_width - knob_width;
			}
		}

		return { transform: `translateX(${pos}px)` };
	}, [position]);

	useEffect(() => {
		if (_delay) clearTimeout(_delay);

		calculateInitialProgress();
	}, [progress]);

	const init = () => {
		setPosition(calculateProgress);
	};

	const getCountUpOptions = useMemo(() => {
		return {
			start,
			end: progress,
			duration: initial ? 0 : 1.8,
			delay: 0,
			preserveValue: true,
			easingFn: (t, b, c, d) => {
				return c * (t /= d) * t * t + b;
			},
			onEnd: handleComplete,
		};
	}, [start, progress, initial]);

	return (
		<div className={getMainClassNames} rel={'pop'}>
			<div className={getBarClassNames} id={'ac-progress-bar'}>
				<div className={getTrackerClassNames} style={getTrackerPosition} />
				<div
					className={getKnobClassNames}
					progress={calculateProgress}
					style={getKnobPosition}
					id={'ac-progress-knob'}
				>
					<div className={getValueClassNames}>
						<CountUp {...getCountUpOptions} />
					</div>
				</div>
			</div>
		</div>
	);
};

export default memo(AcActionProgress);
