// Imports => React
import React, { useMemo } from 'react';
import { inject } from 'mobx-react';
import { observer } from 'mobx-react-lite';
import clsx from 'clsx';

// Imports => Constants
import { INSTRUCTIONS } from '@constants';

// Imports => Atoms
import { AcContainer, AcRow, AcColumn } from '@atoms/ac-grid';
import AcRichContent from '@atoms/ac-rich-content/ac-rich-content.web';

const _CLASSES = {
	MAIN: 'ac-location-required-modal',
	CONTENT: 'ac-location-required-modal__content',
};

const AcLocationRequiredModal = ({ store, callback }) => {
	const isIOS = useMemo(() => {
		return navigator.userAgent.match(/iPhone|iPad|iPod/i);
	}, [navigator]);

	const isAndroid = useMemo(() => {
		return navigator.userAgent.match(/Android/i);
	}, [navigator]);

	const getInstructionURI = useMemo(() => {
		let browser = 'null';
		let object = isIOS
			? INSTRUCTIONS.IOS
			: isAndroid
			? INSTRUCTIONS.ANDROID
			: null;

		if (navigator.userAgent.indexOf('Chrome') !== -1) {
			browser = 'CHROME';
		} else if (navigator.userAgent.indexOf('Safari') !== -1) {
			browser = 'SAFARI';
		} else if (navigator.userAgent.indexOf('Firefox') !== -1) {
			browser = 'FIREFOX';
		}

		if (browser && INSTRUCTIONS[browser]) {
			return INSTRUCTIONS[browser];
		}

		return object;
	}, [isIOS, isAndroid]);

	const getContentClassNames = useMemo(() => {
		return clsx([_CLASSES.CONTENT]);
	}, []);

	const getStyleClassNames = useMemo(() => {
		return clsx([_CLASSES.MAIN]);
	}, []);

	return (
		<div className={getStyleClassNames}>
			<div className={getContentClassNames}>
				<AcContainer fluid className={'h-text--align-center'}>
					<AcRow>
						<AcColumn>
							<AcRichContent
								content={
									'<p class="h-text-body-large">Om deze app goed te kunnen gebruiken is het noodzakelijk dat jij het delen van je locatiegegevens toestaat.</p>'
								}
							/>
							{getInstructionURI && (
								<AcRichContent
									content={`<p class="h-text-body-large"><a href="${getInstructionURI}" target="_blank" rel="nofollow noreferer noopener">Klik hier</a> voor instructies om je locatiegegevens te delen.</p>`}
								/>
							)}
						</AcColumn>
					</AcRow>
				</AcContainer>
			</div>
		</div>
	);
};

export default inject('store')(observer(AcLocationRequiredModal));
