import React, { useEffect, useRef, useState, useMemo } from 'react';
import Lottie from 'lottie-web/build/player/lottie_svg.min.js';
import clsx from 'clsx';

const Direction = {
  FORWARD: 'forward',
  REVERSED: 'reversed',
};

const animationDirection = direction => {
  switch (direction) {
    case Direction.REVERSED:
      return -1;
    case Direction.FORWARD:
    default:
      return 1;
  }
};

const _CLASSES = {
  MAIN: 'ac-lottie',
};

const AcLottieAnimation = ({
  className,
  path,
  animationData,
  width = '100%',
  height = '100%',
  speed = 1,
  autoplay = true,
  loop = true,
  direction = 'forward',
  preserveAspectRatio = 'none',
  getLottie,
}) => {
  const [animation, setAnimation] = useState(null);
  const ref = useRef(null);

  const getMainClassNames = useMemo(() => {
    return clsx(_CLASSES.MAIN);
  }, []);

  useEffect(() => {
    if (animation) animation.destroy();

    const anim = Lottie.loadAnimation({
      container: ref.current, // the dom element
      renderer: 'svg',
      loop,
      autoplay,
      animationData,
      rendererSettings: {
        scaleMode: 'noScale',
        progressiveLoad: false, // Boolean, only svg renderer, loads dom elements when needed. Might speed up initialization for large number of elements.
        hideOnTransparent: true, //Boolean, only svg renderer, hides elements when opacity reaches 0 (defaults to true)
        preserveAspectRatio,
      },
    });
    anim.setSpeed(speed);
    anim.setDirection(animationDirection(direction));

    setAnimation(anim);
    if (getLottie) getLottie(anim);

    return () => {
      if (animation) animation.destroy();
    };
  }, [ref, path, speed, direction, loop, autoplay]);

  return (
    <span
      ref={ref}
      style={{ width, height }}
      className={getMainClassNames}
    ></span>
  );
};

export default AcLottieAnimation;
