// Imports => React
import React from 'react';
import { Fade } from 'react-reveal';

// Imports => Utilities
import { AcIsSet } from '@utils';

// Imports => Controller
import AcTextInputController from './ac-text-input.controller';

// Component
class AcTextInput extends AcTextInputController {
  buildError = () => {
    const { hasError, touched } = this.state;

    if (hasError && !touched) return null;

    return (
      <Fade duration={200}>
        <div className={this.getErrorClassNames()} title={hasError} />
      </Fade>
    );
  };

  buildSuccess = () => {
    return (
      <Fade duration={200}>
        <div className={this.getSuccessClassNames()} />
      </Fade>
    );
  };

  buildInstructions = () => {
    const { instructions } = this.props;
    return (
      <div className={this.getInstructionsClassNames()}>{instructions}</div>
    );
  };

  buildTextInput = () => {
    const {
      value,
      disabled,
      readonly,
      maxLength,
      min,
      max,
      focus,
      label,
      placeholder,
      autocomplete,
    } = this.props;
    const { name, reference, type } = this.state;

    let pattern = null;
    if (type === 'number') pattern = '[-+]?[0-9]*[.,]?[0-9]+';

    let _props = {};

    if (AcIsSet(pattern)) _props = { ..._props, pattern };
    if (AcIsSet(min)) _props = { ..._props, min };
    if (AcIsSet(max)) _props = { ..._props, max };

    if (type === 'number' || type === 'textarea') _props = { ..._props, value };
    else _props = { ..._props, defaultValue: value };

    if (type === 'number') _props = { ..._props, inputMode: 'numeric' };

    if (focus) _props = { ..._props, autoFocus: true };

    if (label) _props = { ..._props, 'aria-label': label };
    else if (placeholder)
      _props = { ..._props, 'aria-label': placeholder, placeholder };

    if (type === 'textarea') {
      return (
        <textarea
          type={type}
          name={name}
          id={reference}
          value={value}
          className={this.getInputClassNames()}
          disabled={disabled}
          readOnly={readonly}
          autoComplete={autocomplete ? autocomplete : 'new-password'}
          tabIndex={0}
          onChange={this.onChange}
          onKeyUp={this.onKeyUp}
          ref={this.element}
          {..._props}
        />
      );
    }

    return (
      <input
        type={type}
        name={name}
        id={reference}
        className={this.getInputClassNames()}
        disabled={disabled}
        readOnly={readonly}
        pattern={pattern}
        autoComplete={autocomplete ? autocomplete : 'new-password'}
        autoCapitalize={'none'}
        autoCorrect={'none'}
        tabIndex={0}
        maxLength={maxLength || 524288}
        onChange={this.onChange}
        onKeyUp={this.onKeyUp}
        onFocus={this.onFocus}
        onBlur={this.onBlur}
        ref={this.element}
        {..._props}
      />
    );
  };

  render() {
    const {
      label,
      placeholder,
      disabled,
      readonly,
      instructions,
      value,
      withSuccess,
    } = this.props;
    const { hasError, reference } = this.state;

    return (
      <div
        ref={node => (this.el = node)}
        className={this.getStyleClassNames()}
        disabled={disabled}
        readOnly={readonly}
      >
        {this.buildTextInput()}
        {(label || placeholder) && (
          <label
            id={`label-${reference}`}
            htmlFor={reference}
            className={this.getLabelClassNames()}
          >
            {label || placeholder}
          </label>
        )}
        {hasError && this.buildError()}
        {!hasError && value && withSuccess && this.buildSuccess()}
        {!hasError && instructions && this.buildInstructions()}
      </div>
    );
  }
}

export default AcTextInput;
